import { twoDigits } from './NumberUtils'

export function getToday() {
  return new Date()
}

export function countDays(month, year) {
  return new Date(year, month + 1, 0).getDate()
}

export function parseDate(string) {
  return new Date(string)
}

export function formatDate(day, month, year) {
  const date = new Date(year, month, day)
  return `${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(
    date.getDate()
  )}`
}

export function getWeekNumbers(month, year) {
  let weekNumbers = []
  for (let i = 1; i <= countDays(month, year); i++) {
    let weekNumber = getWeekNumber(new Date(year, month, i))
    if (month === 11 && weekNumber === 1) {
      weekNumber = weekNumbers[weekNumbers.length - 1] + 1
      weekNumbers.push(weekNumber)
      break
    }
    weekNumbers.push(weekNumber)
  }
  return Array.from(new Set(weekNumbers))
}

export function getWeekNumber(date) {
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))

  // Calculate full weeks to nearest Thursday
  let yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
  const oneDayInMs = 86400000
  return Math.ceil(((date - yearStart) / oneDayInMs + 1) / 7)
}

export function getMonthName(month) {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][month]
}

export function milisecToDHMSConverter(ms) {
  if (ms <= 0) return '--'

  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30) // Approximation
  const years = Math.floor(months / 12)

  const remainingMonths = months % 12
  const remainingDays = days % 30
  const remainingHours = hours % 24
  const remainingMinutes = minutes % 60
  const remainingSeconds = seconds % 60

  // Build the result string, excluding 0 values
  const parts = []
  if (years > 0) parts.push(`${years}y`)
  if (remainingMonths > 0) parts.push(`${remainingMonths}mo`)
  if (remainingDays > 0) parts.push(`${remainingDays}d`)
  if (remainingHours > 0) parts.push(`${remainingHours}h`)
  if (remainingMinutes > 0) parts.push(`${remainingMinutes}m`)
  if (remainingSeconds > 0) parts.push(`${remainingSeconds}s`)

  // If no parts are added, return '--'
  if (parts.length === 0) return '--'

  // Join the parts with spaces
  return parts.join(' ')
}
export function secToDHMSConverter(s) {
  if (s <= 0) return '--'

  const minutes = Math.floor(s / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30) // Approximation
  const years = Math.floor(months / 12)

  const remainingMonths = months % 12
  const remainingDays = days % 30
  const remainingHours = hours % 24
  const remainingMinutes = minutes % 60
  const remainingSeconds = s % 60

  // Build the result string, excluding 0 values
  const parts = []
  if (years > 0) parts.push(`${years}y`)
  if (remainingMonths > 0) parts.push(`${remainingMonths}mo`)
  if (remainingDays > 0) parts.push(`${remainingDays}d`)
  if (remainingHours > 0) parts.push(`${remainingHours}h`)
  if (remainingMinutes > 0) parts.push(`${remainingMinutes}m`)
  if (remainingSeconds > 0) parts.push(`${remainingSeconds}s`)

  // If no parts are added, return '--'
  if (parts.length === 0) return '--'

  // Join the parts with spaces
  return parts.join(' ')
}
